import React, {useState, useEffect, useRef, useCallback, useReducer, useMemo} from 'react';
import styled from 'styled-components';
import {Code} from 'react-content-loader'
import axios from 'axios'
import {Input} from "../../ira/ui/Inputs";
import {BlueButton} from "../../ira/ui/Buttons";
import {DEFAULT_VIDEO_PLACEHOLDER} from "../../../constants/config";

export default function SimpleAISearchTool(props) {
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);

    return (
        <Wrapper>

            <Row>
                <Label>
                    Search query
                </Label>
                <Input value={text} placeholder={'Your search query'} onChange={evt => {
                    setText(evt.target.value);
                }}/>
            </Row>

            <Row>
                <BlueButton onClick={async () => {
                    if (loading == true) {
                        return;
                    }
                    setItems([]);
                    setLoading(true);
                    let pld = (await axios.post(`https://api.entsu.ru/tools/qdrant/find_video`, {
                        "text": text,
                        limit: 30
                    })).data || [];
                    console.log('got matches = ', pld);
                    setItems(pld);
                    setLoading(false);
                }}>
                    {loading == true ? 'Searching...' : 'Search'}
                </BlueButton>
            </Row>

            {items.map((a, i) => {
                let x = a.payload;
                let score = ((+a.score) * 100).toFixed(1);
                return (
                    <SearchItem key={a.id}>
                        <div style={{marginBottom: '7px'}}>
                            {x.name == undefined ? null :
                                <span style={{marginRight: 15, fontWeight: 'bold'}}>
                                    {`${x.name}`}
                                </span>
                            }
                            <span>
                                {`Type: ${x.source_type} | relevance: ${score}%`}
                            </span>

                        </div>
                        <VideoPlaceholder>
                            <Video controls={true}
                                   poster={x.avatar || DEFAULT_VIDEO_PLACEHOLDER}
                            >
                                <source src={x.url}/>
                            </Video>
                        </VideoPlaceholder>

                        <TranscriptPlaceholder className={''}>
                            {x.transcript}
                        </TranscriptPlaceholder>

                    </SearchItem>
                )
            })}

        </Wrapper>
    );
}

const Wrapper = styled.div`

`;

const SearchItem = styled.div`
  margin-bottom: 10px;
  padding: 7px;
  background: white;
`;

const Row = styled.div`
  margin-bottom: 10px;
`;

const VideoPlaceholder = styled.div`
  background: lightgrey;
  height: 400px;
  position: relative;
  width: 100%;
`;

const TranscriptPlaceholder = styled.div`
  padding: 10px;
  border-radius: 5px;
  background: rgba(0, 0, 0, 0.04);
  margin-bottom: 5px;
`;

const Label = styled.div`
  font-weight: bold;
  margin-bottom: 3px;
`;

const Video = styled.video`
  box-sizing: border-box;
  background: lightgrey;
  width: 100%;
  height: 100%;
  object-fit: contain;
  position: absolute;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  background: rgba(0, 0, 0, 0.04);
`;
